import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import RichText from "../components/RichText/RichText"
import Search from "../components/Search/Search"
import { Disqus } from "gatsby-plugin-disqus"

const SectionTemplate = ({ data, location, pageContext }) => {
  const page = data.contentfulPage
  const config = data.site.siteMetadata

  let disqusConfig = {
    url: `${config.siteUrl + location.pathname}`,
    identifier: page.id,
    title: page.title,
  }

  return (
    <Layout location={location}>
      <Seo title={page.title} description="" url={location.pathname} />

      <div className="grid">
        <div className="grid__sidebar">
          <h2 style={{ marginTop: "2.9em" }}>Search</h2>
          <Search />
        </div>
        <div className="grid__main">
          <p>
            Part of:{" "}
            <Link to={`/${page.section.slug}/`.replace("//", "")}>
              {page.section.title}
            </Link>
          </p>
          <h1>{page.title}</h1>
          <p>Last updated: {page.updatedAt}</p>
          <RichText paragraph={page.mainContent} />

          <hr style={{ marginBottom: "2em" }} />
          <Disqus config={disqusConfig} />
        </div>
      </div>
    </Layout>
  )
}

export default SectionTemplate

export const pageQuery = graphql`
  query ($slug: String!, $sectionSlug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allContentfulSection(sort: { fields: menuOrder }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulPage(
      filter: { section: { slug: { eq: $sectionSlug } } }
      sort: { fields: menuOrder }
    ) {
      edges {
        node {
          title
          slug
          section {
            slug
          }
        }
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      mainContent {
        raw
        references {
          ... on ContentfulPage {
            __typename
            contentful_id
            slug
            title
            section {
              slug
            }
          }
          ... on ContentfulSection {
            __typename
            contentful_id
            slug
            title
          }
        }
      }
      section {
        slug
        title
      }
      updatedAt(formatString: "D MMMM, YYYY")
    }
  }
`
